<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn v-if="editable && isOld" class="custom-btn" label="QR 코드" icon="qr_code_scanner" color="black" size="md">
                  <q-popup-proxy>
                    <c-card title="QR 코드 출력" class="cardClassDetailForm">
                      <template slot="card-button">
                        <c-btn label="인쇄" icon="print" @btnClicked="print" />
                      </template>
                      <template slot="card-detail">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="wrap" action="#" id="printJS-form">
                            <vue-qrcode
                              :value="`${serverName}/sop/fft/fireFightingMaster?sopFireFightingId=${popupParam.sopFireFightingId}`" 
                              :options="{ width: 180 }"
                              class="canvas"
                            >
                            </vue-qrcode>
                          </div>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="fireFighting"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="소방설비명"
                  name="fireFightingName"
                  v-model="fireFighting.fireFightingName">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="관리번호"
                  name="manageNo"
                  v-model="fireFighting.manageNo">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingTypeCd"
                  label="설비유형"
                  v-model="fireFighting.sopFireFightingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :comboItems="kindItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingKindCd"
                  label="설비종류"
                  v-model="fireFighting.sopFireFightingKindCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  label="수량"
                  type="number"
                  name="amount"
                  v-model="fireFighting.amount">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-danger-area
                  :editable="editable"
                  :plantCd="fireFighting.plantCd"
                  label="관련 위험물저장소"
                  name="chmDangerMstId"
                  v-model="fireFighting.chmDangerMstId"
                ></c-danger-area>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="fireFighting.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-dept-multi 
                  :editable="editable"
                  :parentCheckDepts="fireFighting.deptCd" 
                  :plantCd="fireFighting.plantCd" 
                  label="관리부서" 
                  name="deptCd" 
                  v-model="fireFighting.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="multiple"
                  name="processCd"
                  v-model="fireFighting.processCd">
                </c-process>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  label="설치위치"
                  name="location"
                  v-model="fireFighting.location">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :editable="editable"
                  label="설치일"
                  name="installDate"
                  v-model="fireFighting.installDate"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-select
                  :editable="editable"
                  :comboItems="disposeFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="disposeFlag"
                  label="폐기여부"
                  v-model="fireFighting.disposeFlag"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :required="fireFighting.disposeFlag==='Y'"
                  :editable="editable"
                  :disabled="fireFighting.disposeFlag==='N'"
                  label="폐기일"
                  name="disposeDate"
                  v-model="fireFighting.disposeDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-table
        v-if="isOld"
        ref="table"
        title="교체이력"
        :gridHeight="setheight"
        :columns="grid.columns"
        :data="fireFighting.replacementHistroies"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        :isExcelDown="false"
        :isFullScreen="false"
        selection="multiple"
        rowKey="sopFireFightingReplacementHistoryId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable" 
              :showLoading="false" 
              label="추가" 
              icon="add" 
              @btnClicked="addReplacementHistory" />
            <c-btn 
              v-if="editable" 
              label="제외" 
              icon="remove" 
              @btnClicked="deleteReplacementHistory" />
          </q-btn-group>
        </template>
      </c-table>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import printJs from 'print-js'
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
  name: 'fireFightingInfo',
  components: {
    VueQrcode
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        sopFireFightingKindCd: null,  // 점검 소방시설 종류
        sopFireFightingKindName: '',  // 점검 소방시설 종류
        manageNo: '',  // 관리번호
        fireFightingName: '',  // 소방설비명
        deptCd: '',  // 관리부서
        processCd: '',  // 관련공정
        sopFireFightinhReplacementCycleCd: '',  // 소방설비 교체 주기
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        location: '',  // 설비위치
        installDate: '',  // 설치일
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        deleteFlag: 'N',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        replacementHistroies: [],
        deleteReplacementHistroies: [],
        histroy: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'replacementDt',
            field: 'replacementDt',
            // 교체일시
            label: '교체일시',
            align: 'center',
            style: 'width:150px',
            type: 'datetime',
            datetimeType: 'datetime',
            minuteStep: 10,
            sortable: false,
            required: true,
          },
          {
            name: 'replacementUserName',
            field: 'replacementUserName',
            // 교체자
            label: '교체자',
            align: 'center',
            style: 'width:120px',
            type: 'user',
            userId: 'replacementUserId',
            sortable: false,
            required: true,
          },
          {
            name: 'replacementContents',
            field: 'replacementContents',
            // 교체 내용
            label: '교체 내용',
            align: 'left',
            style: 'width:250px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            // 비고
            label: '비고',
            align: 'left',
            style: 'width:100px',
            type: 'textarea',
            sortable: false,
          },
        ],
        height: '350px'
      },
      serverName: '',
      kindItems: [],
      editable: true,
      isSave: false,
      saveUrl: '',
      checkUrl: '',
      hostNameUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'fireFighting.sopFireFightingTypeCd'() {
      this.setKind();
    }
  },
  computed: {
    setheight() {
      return String(this.contentHeight - 550) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.checkUrl = selectConfig.fft.fireFighting.check.url
      this.hostNameUrl = selectConfig.com.mobileHostname.url;
      this.saveUrl = transactionConfig.fft.fireFighting.update.url
      // code setting
      // list setting
      this.setKind();
      this.serverUrl();
    },
    setKind() {
      if (this.fireFighting.sopFireFightingTypeCd) {
        this.$comm.getComboItems('SOP_FIRE_FIGHTING_KIND_CD', this.fireFighting.sopFireFightingTypeCd).then(_result => {
          this.kindItems = _result;
          if (this.$_.findIndex(this.kindItems, { code: this.fireFighting.sopFireFightingKindCd }) === -1) {
            this.$set(this.fireFighting, 'sopFireFightingKindCd', null)
          }
        });
      } else {
        this.kindItems = [];
      }
    },
    serverUrl() {
      this.$http.url = this.hostNameUrl;
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
      );
    },
    addReplacementHistory() {
      this.fireFighting.replacementHistroies.splice(0, 0, {
        sopFireFightingReplacementHistoryId: uid(),  // 소방설비 교체 이력 일련번호
        sopFireFightingId: this.popupParam.sopFireFightingId,  // 소방설비 일련번호
        replacementDt: this.$comm.getTodayDateTiemMin(),  // 교체일시
        replacementUserId: this.$store.getters.user.userId,  // 교체자
        replacementUserName: this.$store.getters.user.userName,  // 교체자
        replacementContents: '',  // 교체 내용
        remarks: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    deleteReplacementHistory() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.fireFighting.deleteReplacementHistroies) this.fireFighting.deleteReplacementHistroies = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.fireFighting.deleteReplacementHistroies, { sopFireFightingReplacementHistoryId: item.sopFireFightingReplacementHistoryId }) === -1) {
                  this.fireFighting.deleteReplacementHistroies.push(item)
                }
              this.fireFighting.replacementHistroies = this.$_.reject(this.fireFighting.replacementHistroies, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
          
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFighting.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFighting.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl
          this.$http.type = 'GET';
          this.$http.param = {
            sopFireFightingId: this.popupParam.sopFireFightingId,
            manageNo: this.fireFighting.manageNo,
          }
          this.$http.request((_result) => {
            if (!_result.data) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.fireFighting.regUserId = this.$store.getters.user.userId;
                  this.fireFighting.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message: '동일한 관리번호가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingId', result.data)
      this.$emit('getDetail');
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
  }
};
</script>